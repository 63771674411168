import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Button,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FC, useRef, useState } from 'react';
import { useMatch, Link as RouterLink } from 'react-router-dom';
import { Tracker } from '../../../utils/tracker';
import { useAuth } from '../../../hooks';
import { Contact } from '../../../../../../types/api';
import { getCurrentFlow } from '../../../pages/dashboard/loan_detail/flows';

export type NavItem = {
  path: string;
  label: string;
  icon?: React.ReactNode;
  depth?: number;
  items?: NavItem[];
};

const usenavItemstyles = makeStyles({
  selected: {
    fontWeight: 700,
    borderBottom: '2px solid green',
  },
});

function flattenRoutes(items: NavItem[]): NavItem[] {
  return items.reduce<NavItem[]>((acc, item) => {
    if (item.items) {
      return [...acc, item, ...flattenRoutes(item.items)];
    }
    return [...acc, item];
  }, []);
}

const NestedMenuItem: FC<{ item: NavItem; closeMenu: VoidFunction }> = ({
  item,
  closeMenu,
}) => {
  const partialMatch = useMatch({ path: item.path, end: false });
  const fullMatch = useMatch({ path: item.path, end: true });
  const dynamicFontSize = 'clamp(12px, 1vw, 14px)';

  const tracker = Tracker.getInstance();

  return (
    <MenuItem
      key={item.path}
      onClick={() => {
        closeMenu();

        tracker.track(Tracker.EVENTS.NAVBAR_ITEM_CLICKED, {
          navItem: item.label,
        });
      }}
      component={RouterLink}
      sx={{
        pl: item.depth ? item.depth * 1 : undefined,
        py: { xs: '1.5rem', md: 1.25 },
      }}
      to={item.path}
    >
      <ListItemIcon>{item.icon}</ListItemIcon>
      <ListItemText
        primaryTypographyProps={{
          fontWeight: partialMatch || fullMatch ? 700 : undefined,
          fontSize: dynamicFontSize,
        }}
      >
        {item.label}
      </ListItemText>
    </MenuItem>
  );
};

const NavRootItem: FC<NavItem> = ({ path, label, items, icon, depth }) => {
  const isActive = useMatch({ path, end: false });
  const classes = usenavItemstyles();
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => setIsOpen(false);
  const menuAnchor = useRef<HTMLButtonElement | null>(null);
  const hasNestedItems = items && items.length > 0;
  // we want to flatten the routes inside a nav item so that we can
  // check if any of the nested routes are active
  const flattenedRoutes = flattenRoutes(items || []);

  const theme = useTheme();
  const mobileMQ = useMediaQuery('(max-width:900px)');
  const dynamicFontSize = 'clamp(12px, 1vw, 14,4px)';

  const tracker = Tracker.getInstance();

  const { user } = useAuth();
  const flow = getCurrentFlow(user as Contact);

  return (
    <>
      <Typography
        component="li"
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={isActive && !mobileMQ ? classes.selected : undefined}
        sx={{
          px: { xs: 1.5, md: 0 },
          mx: { xs: 0, md: 3 },
          py: { xs: '2rem', md: 0 },
          width: { xs: '100%', md: 'fit-content' },
          justifyContent: { xs: 'left', md: 'center' },
          borderBottom: {
            xs: `1px solid ${theme.palette.divider}`,
            md: 'none',
          },
          fontWeight: { xs: '800', md: '500' },
        }}
        fontSize={dynamicFontSize}
      >
        {/* Has nested items and layout is desktop */}
        {hasNestedItems && !mobileMQ && (
          <>
            <Link
              onClick={() => {
                setIsOpen(!isOpen);

                tracker.track(Tracker.EVENTS.NAVBAR_ITEM_CLICKED, {
                  navItem: label,
                });
              }}
              color="textPrimary"
              variant="inherit"
              underline="none"
              ref={menuAnchor}
              component={Button}
              endIcon={isOpen ? <ExpandLess /> : <ExpandMore />}
              sx={{
                color: 'text.primary',
                px: 0,
                py: 0.5,
                '&:hover': {
                  backgroundColor: 'transparent',
                },
                fontSize: dynamicFontSize,
                fontWeight: 'inherit',
              }}
            >
              {label}
            </Link>
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={menuAnchor.current}
              open={isOpen}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              sx={{
                py: 0,
                '&:[role="menuitem"]': {
                  borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                },
              }}
            >
              {flattenedRoutes?.map((item) => (
                <NestedMenuItem
                  key={item.path}
                  item={item}
                  closeMenu={handleClose}
                />
              ))}
            </Menu>
          </>
        )}
        {/* Has nested items and layout is mobile, displays the nested links inside an Accordion */}
        {hasNestedItems && mobileMQ && (
          <Accordion
            sx={{
              boxShadow: 'none',
              border: 'none',
              '&:before': {
                display: 'none',
              },
              '& .MuiAccordionSummary-root': {
                minHeight: 'fit-content',
              },
              '&.Mui-expanded': {
                margin: 0,
                '& .MuiAccordionSummary-root': {
                  borderBottom: 'none',
                },
              },
              width: '100%',
              padding: 0,
            }}
          >
            <AccordionSummary
              id="panel-header"
              aria-controls="panel-content"
              expandIcon={isOpen ? <ExpandLess /> : <ExpandMore />}
              sx={{
                padding: 0,
                '&.Mui-expanded': {
                  minHeight: 'auto',
                  paddingBottom: 0,
                  marginTop: 0,
                  '& .MuiAccordionSummary-content': {
                    margin: 0,
                  },
                },
                '& .MuiAccordionSummary-content': {
                  margin: 0,
                },
                width: '100%',
                maxHeight: 'fit-content',
              }}
              onClick={() => {
                setIsOpen(!isOpen);

                tracker.track(Tracker.EVENTS.NAVBAR_ITEM_CLICKED, {
                  navItem: label,
                });
              }}
            >
              {label}
            </AccordionSummary>
            <AccordionDetails
              sx={{
                marginTop: 3,
              }}
            >
              {flattenedRoutes?.map((item) => (
                <NestedMenuItem
                  key={item.path}
                  item={item}
                  closeMenu={handleClose}
                />
              ))}
            </AccordionDetails>
          </Accordion>
        )}
        {/* Normal link in Desktop layout */}
        {!hasNestedItems && (
          <Link
            component={RouterLink}
            to={path}
            color="textPrimary"
            variant="inherit"
            underline="none"
            sx={{ fontSize: '14.4px' }}
            onClick={() => {
              tracker.track(Tracker.EVENTS.NAVBAR_ITEM_CLICKED, {
                navItem: label,
              });

              if (label === 'Dashboard') {
                tracker.trackSectionLeftToDashboard(flow);
              }
            }}
          >
            {label}
          </Link>
        )}
      </Typography>
    </>
  );
};

export default NavRootItem;

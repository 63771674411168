import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { genericRequiredFieldError } from '../../constants';
import { ValidationSchema } from '../interfaces';
import { Validation } from '../Validation';

const {
  formField: { borrowerDba },
} = loanFormModel;

export class BorrowerDBAValidation implements ValidationSchema {
  public name: string = borrowerDba.name;
  public label: string = borrowerDba.label;
  public dependsOn: string[] = [];
  public type = 'string';
  public required = {
    message: genericRequiredFieldError,
    value: borrowerDba.required,
  };
  public nullable = true;
  public max = {
    value: 255,
    message: 'Borrower DBA must be less than 255 characters',
  };
}
export default new Validation(new BorrowerDBAValidation());

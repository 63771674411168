import { Contact } from '../../../../../../../types/api';
import { ApplicationData } from '../LoanDetailTypes';
import { APPLICATION_FLOW_TYPE } from './constants';
import {
  applicationSteps,
  applicationStepsByIDs,
} from './MainFlow/ApplicationStepsConfig';

export const getApplicationSteps = (data: ApplicationData): any => {
  return { applicationSteps, applicationStepsByIDs };
};

export const getCurrentFlow = (contact: Contact): APPLICATION_FLOW_TYPE => {
  if (contact?.feature_flags?.split(';').indexOf('Welcome Page V2') !== -1)
    return APPLICATION_FLOW_TYPE.NEW_WELCOME_PAGE;
  return APPLICATION_FLOW_TYPE.MAIN;
};

export {};

import { formatSectionAndFieldName } from './helpers';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _kmq: any[];
  }
}

export class Tracker {
  private static instance: Tracker;
  private constructor() {}

  /*    
    Event nomenclature goes a long way. 
    
    When adding new events, try to:

    - Use prefixes to groups related events. 
    - Understandable names. 
      app-table-tab-change vs 'nav-change'
    - Decouple from things that might change, CTA copy. 
      'application-begin' vs 'clicked-get-started' 
  */
  public static EVENTS = {
    // User and account
    LOGOUT: 'user-logout',
    LOGIN: 'user-login',
    FORGOT_PASSWORD: 'user-forgot-password',
    REGISTER_CLICKED: 'user-register-clicked',
    REGISTER_SUCCESS: 'user-register-success',
    REGISTER_ERROR: 'user-register-error',

    // Navbar
    NAVBAR_ITEM_CLICKED: 'navbar-item-clicked',

    // New applicant invites
    NEW_APPLICANT_ERROR: 'new-applicant-error',
    NEW_APPLICANT_SUBMIT: 'new-applicant-submit',
    NEW_APPLICANT_CANCEL: 'new-applicant-cancel',
    NEW_APPLICANT_CTA_CLICKED: 'new-applicant-cta-clicked',
    NEW_APPLICANT_BACK_CLICKED: 'new-applicant-back-clicked',

    // Applications table
    APP_TABLE_NAV_CHANGE: 'app-table-tab-change',
    APP_TABLE_ROW_EXPAND: 'app-table-row-expand',
    APP_TABLE_ROW_COLLAPSE: 'app-table-row-collapse',

    // Pricing calculator
    PRICING_CALCULATOR_BACK: 'pricing-calculator-back',
    PRICING_CALCULATOR_SUBMIT: 'pricing-calculator-submit',
    PRICING_CALCULATOR_ERROR: 'pricing-calculator-error',

    // This is to track the usage of the info tooltip modals on each loan type button
    PRICING_CALCULATOR_LOAN_TYPE_MODAL_OPENED:
      'pricing-calculator-loan-type-modal-opened',

    PRICING_CALCULATOR_LOAN_SUMMARY_DRAWER_USED_IN_MOBILE:
      'pricing-calculator-loan-summary-drawer-used-in-mobile',

    // Price and Prequalify Calculator Hover events:
    PRICING_CALCULATOR_SPECIAL_PAYMENT_TOOLTIP:
      'pricing-calculator-special-payment-tooltip',
    PRICING_CALCULATOR_MONTHLY_PAYMENT_TOOLTIP:
      'pricing-calculator-monthly-payment-tooltip',
    PRICING_CALCULATOR_NET_FUNDING_TOOLTIP:
      'pricing-calculator-net-funding-tooltip',
    PRICING_CALCULATOR_TOTAL_LOAN_AMOUNT_TOOLTIP:
      'pricing-calculator-total-loan-amount-tooltip',

    // Prequalify calc section events
    PREQUALIFY_CALC_SUCCESS: 'prequalify-calc-success',
    PREQUALIFY_CALC_ERROR: 'prequalify-calc-error',

    // Quote page events
    QUOTE_DOWNLOAD_PDF_CLICKED: 'quote-download-pdf-clicked',
    QUOTE_INITIALIZE_APP_CLICKED: 'quote-initialize-app-clicked',
    QUOTE_VIEW_RATE_SHEETS_CLICKED: 'quote-view-rate-sheets-clicked',

    // Projects table
    PROJECT_TABLE_NAV_CHANGE: 'project-table-tab-change',

    // Field time tracking
    FIELD_FOCUSED: (name: string) =>
      `${formatSectionAndFieldName(name)}-field-focused`,
    FIELD_BLURRED: (name: string) =>
      `${formatSectionAndFieldName(name)}-field-blurred`,

    // Section time tracking

    // Need to format the name to match the format in the tracker
    SECTION_ENTERED: (titleId: string) =>
      `loan-app-section-entered-${formatSectionAndFieldName(titleId)}`,
    SECTION_CHANGED: (titleId: string) =>
      `loan-app-sidebar-clicked-${formatSectionAndFieldName(titleId)}`,
    SECTION_CONTINUED: (titleId: string) =>
      `loan-app-section-clicked-continue-${formatSectionAndFieldName(titleId)}`,
    SECTION_BACK: (titleId: string) =>
      `loan-app-section-clicked-back-${formatSectionAndFieldName(titleId)}`,
    SECTION_SAVED_FOR_LATER: (titleId: string) =>
      `loan-app-section-clicked-save-for-later-${formatSectionAndFieldName(
        titleId
      )}`,
    SECTION_SAVED_FOR_LATER_CONFIRMED: (titleId: string) =>
      `loan-app-section-clicked-save-for-later-confirmed-${formatSectionAndFieldName(
        titleId
      )}`,
    SECTION_LEFT_TO_DASHBOARD: 'loan-app-section-clicked-dashboard',
    COMPLETED_APPLICATION: 'loan-app-section-completed-application',
  };

  public static getInstance(): Tracker {
    if (!Tracker.instance) {
      Tracker.instance = new Tracker();
    }
    return Tracker.instance;
  }

  public track(event: string, properties?: Record<string, any>): void {
    const eventProperties = {
      ...properties,
      rosefieldVersion: __versionString__,
    };

    if (typeof window._kmq !== 'undefined') {
      window._kmq.push(['record', event, eventProperties]);
    } else {
      console.error('_kmq is not defined');
    }
  }

  public trackFieldFocus = (name: string, flow: string) => {
    // We normalize the name to remove the dot notation (i.e.: principals[0].fieldname)
    const normalizedName = name.match(/[^.]+$/)?.[0] || name;
    this.track(Tracker.EVENTS.FIELD_FOCUSED(normalizedName), { flow });
  };

  public trackFieldBlur = (name: string, flow: string) => {
    // We normalize the name to remove the dot notation (i.e.: principals[0].fieldname)
    const normalizedName = name.match(/[^.]+$/)?.[0] || name;
    this.track(Tracker.EVENTS.FIELD_BLURRED(normalizedName), { flow });
  };

  public trackSectionEntered = (titleId: string, flow: string) => {
    this.track(Tracker.EVENTS.SECTION_ENTERED(titleId), { flow });
  };

  public trackSectionChanged = (titleId: string, flow: string) => {
    this.track(Tracker.EVENTS.SECTION_CHANGED(titleId), { flow });
  };

  public trackSectionContinued = (titleId: string, flow: string) => {
    this.track(Tracker.EVENTS.SECTION_CONTINUED(titleId), { flow });
  };

  public trackSectionBack = (titleId: string, flow: string) => {
    this.track(Tracker.EVENTS.SECTION_BACK(titleId), { flow });
  };

  public trackSectionSavedForLater = (titleId: string, flow: string) => {
    this.track(Tracker.EVENTS.SECTION_SAVED_FOR_LATER(titleId), { flow });
  };

  public trackSectionSavedForLaterConfirmed = (
    titleId: string,
    flow: string
  ) => {
    this.track(Tracker.EVENTS.SECTION_SAVED_FOR_LATER_CONFIRMED(titleId), {
      flow,
    });
  };

  public trackSectionLeftToDashboard = (flow: string) => {
    this.track(Tracker.EVENTS.SECTION_LEFT_TO_DASHBOARD, { flow });
  };

  public trackCompletedApplication = (flow: string) => {
    this.track(Tracker.EVENTS.COMPLETED_APPLICATION, { flow });
  };

  public identify(email: string): void {
    if (typeof window._kmq !== 'undefined') {
      window._kmq.push(['identify', email]);
    } else {
      console.error('_kmq is not defined');
    }
  }
}

import { FC, memo, ReactNode } from 'react';
import { MenuItem, SelectChangeEvent, SxProps, Theme } from '@mui/material';
import StyledInput, { StyledInputProps } from '../inputs/StyledInput';
import { Control, Controller, FieldValues } from 'react-hook-form';
import { Choice } from '../../../../../types/utility';

type SelectFieldProps<
  T extends FieldValues = any,
  Name extends keyof T = any
> = {
  /**Optional if used inside react-hook-form FormProvider */
  control?: Control<T>;
  name: Name;
  choices: Choice<T[Name]>[];
  placeholderLabel?: string;
  isDisabled?: boolean;
  readonly?: boolean;
  multiple?: boolean;
  touched?: boolean;
  onChange?: (
    event: SelectChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    child: ReactNode
  ) => void; // Updated type definition
  containerSx?: SxProps<Theme>;
} & Omit<StyledInputProps, 'type' | 'ref' | 'defaultValue'>;

const SelectField: FC<SelectFieldProps> = (props) => {
  const {
    control,
    name,
    label,
    choices,
    placeholderLabel,
    isDisabled,
    readOnly,
    multiple,
    touched: defaultTouched,
    onChange,
    containerSx,
    ...rest
  } = props;

  const id = props.id ?? props.name;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const { isTouched: fieldTouched, error } = fieldState;
        const touched = defaultTouched || fieldTouched;
        const isError = Boolean(touched && error);
        function _renderHelperText() {
          if (isError) {
            return error?.message;
          }
        }
        return (
          <StyledInput
            select
            label={label}
            disabled={isDisabled}
            helperText={_renderHelperText()}
            SelectProps={{
              displayEmpty: true,
              inputProps: { readOnly },
              onChange: (event) => {
                field.onChange(event);
                if (onChange)
                  onChange(
                    event as SelectChangeEvent<
                      HTMLTextAreaElement | HTMLInputElement
                    >,
                    event.target
                  ); // Cast event to the correct type
              },
            }}
            id={id}
            error={isError}
            {...rest}
            {...field}
            value={field.value ?? ''}
            multiple={multiple}
            containerSx={{ ...containerSx }}
            onFocus={(e) => {
              rest.onFocus?.(e); // Preserve additional onFocus from `...rest`
              (field as any).onFocus?.(e); // Ensure RHF validation runs (Need to declare it as unknown to avoid type error. Typescript doesn't know that field is a Controller field which in fact has onFocus)
            }}
            onBlur={(e) => {
              rest.onBlur?.(e); // Preserve additional onBlur from `...rest`
              (field as any).onBlur?.(e); // Ensure RHF validation runs (Need to declare it as unknown to avoid type error. Typescript doesn't know that field is a Controller field which in fact has onBlur)
            }}
          >
            <MenuItem value="" disabled>
              <em>{placeholderLabel ?? 'Please choose a value'}</em>
            </MenuItem>
            {choices.map((item, index) => (
              <MenuItem key={index} value={item.value} disabled={item.disabled}>
                {item.label}
              </MenuItem>
            ))}
          </StyledInput>
        );
      }}
    />
  );
};

export default memo(SelectField);

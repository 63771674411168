import * as Yup from 'yup';

import {
  BorrowerIsHighRiskValidation,
  BorrowerIsHighRiskExplanationValidation,
  PrimaryContactNameValidation,
  PrimaryContactPhoneNumberValidation,
  PrimaryContactEmailValidation,
} from '../../../../../validations/welcome_page';

import {
  BusinessAddressCityValidation,
  BusinessAddressStateValidation,
  BusinessAddressValidation,
  BusinessAddressZipCodeValidation,
  MailingAddressCityValidation,
  MailingAddressSameAsBusinessPicklistValidation,
  MailingAddressStateValidation,
  MailingAddressValidation,
  MailingAddressZipCodeValidation,
} from '../../../../../validations/borrower_information';

export const borrowerInformationValidation = {
  [PrimaryContactNameValidation.name]:
    PrimaryContactNameValidation.buildSchema(),
  [PrimaryContactEmailValidation.name]:
    PrimaryContactEmailValidation.buildSchema(),
  [PrimaryContactPhoneNumberValidation.name]:
    PrimaryContactPhoneNumberValidation.buildSchema(),
  [MailingAddressSameAsBusinessPicklistValidation.name]:
    MailingAddressSameAsBusinessPicklistValidation.buildSchema(),
  [MailingAddressValidation.name]: MailingAddressValidation.buildSchema(),
  [MailingAddressCityValidation.name]:
    MailingAddressCityValidation.buildSchema(),
  [MailingAddressStateValidation.name]:
    MailingAddressStateValidation.buildSchema(),
  [MailingAddressZipCodeValidation.name]:
    MailingAddressZipCodeValidation.buildSchema(),
  [BorrowerIsHighRiskValidation.name]:
    BorrowerIsHighRiskValidation.buildSchema(),
  [BorrowerIsHighRiskExplanationValidation.name]:
    BorrowerIsHighRiskExplanationValidation.buildSchema(),
};

export const businessAddressValidation = {
  [BusinessAddressValidation.name]: BusinessAddressValidation.buildSchema(),
  [BusinessAddressCityValidation.name]:
    BusinessAddressCityValidation.buildSchema(),
  [BusinessAddressStateValidation.name]:
    BusinessAddressStateValidation.buildSchema(),
  [BusinessAddressZipCodeValidation.name]:
    BusinessAddressZipCodeValidation.buildSchema(),
};

export const businessIdentification = {
  ...borrowerInformationValidation,
  ...businessAddressValidation,
};

export const businessIdentificationSchema = Yup.object().shape(
  businessIdentification
);

import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { genericRequiredFieldError } from '../../constants';
import { ValidationSchema } from '../interfaces';
import { Validation } from '../Validation';

const {
  formField: { borrowerBusinessName },
} = loanFormModel;

export class BorrowerBusinessNameValidation implements ValidationSchema {
  public name: string = borrowerBusinessName.name;
  public label: string = borrowerBusinessName.label;
  public dependsOn: string[] = [];
  public type = 'string';
  public required = {
    message: genericRequiredFieldError,
    value: borrowerBusinessName.required,
  };
  public nullable = false;
  public max = {
    value: 255,
    message: 'Borrower Business Name must be less than 255 characters',
  };
}
export default new Validation(new BorrowerBusinessNameValidation());

import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { genericRequiredFieldError } from '../../constants';
import { ValidationSchema } from '../interfaces';
import { Validation } from '../Validation';

const {
  formField: { primaryContactName },
} = loanFormModel;

export class PrimaryContactNameValidation implements ValidationSchema {
  public name: string = primaryContactName.name;
  public label: string = primaryContactName.label;
  public dependsOn: string[] = [];
  public type = 'string';
  public required = {
    message: genericRequiredFieldError,
    value: primaryContactName.required,
  };
  public nullable = true;
  public max = {
    value: 255,
    message: 'Contact Name must be less than 255 characters',
  };
}
export default new Validation(new PrimaryContactNameValidation());

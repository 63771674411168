/* eslint-disable indent */
import { FC, memo, ReactNode } from 'react';
import { InputAdornment } from '@mui/material';
import StyledInput, { StyledInputProps } from '../inputs/StyledInput';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { Control, Controller } from 'react-hook-form';

type NumberFieldProps = {
  /**Optional if used inside react-hook-form FormProvider */
  control?: Control<any>;
  onValueChange?: (values: any) => void;
  isDisabled?: boolean;
  readonly?: boolean;
  name: string;
  startAdornmentText?: string;
  endAdornmentText?: string;
  touched?: boolean;
  max?: number;
  min?: number;
  warning?: string;
  showError?: boolean;
} & NumberFormatProps<Omit<StyledInputProps, 'type' | 'ref' | 'defaultValue'>>;

const NumberField: FC<NumberFieldProps> = (props) => {
  const {
    control,
    name,
    isDisabled,
    readOnly,
    onValueChange,
    startAdornmentText,
    endAdornmentText,
    thousandSeparator,
    decimalScale = 0,
    format,
    max,
    min,
    warning,
    touched: defaultTouched,
    InputProps,
    showError = true,
    ...rest
  } = props;

  let startAdornment: ReactNode | undefined = undefined;
  if (startAdornmentText) {
    startAdornment = (
      <InputAdornment position="start">{startAdornmentText}</InputAdornment>
    );
  }
  let endAdornment: ReactNode | undefined = undefined;
  if (endAdornmentText) {
    endAdornment = (
      <InputAdornment position="end">{endAdornmentText}</InputAdornment>
    );
  }

  const id = props.id ?? props.name;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const { error, isTouched } = fieldState;
        const touched = defaultTouched || isTouched;

        function _renderHelperText() {
          if (touched && error) {
            return error?.message;
          }
        }

        function _renderWarningText() {
          if (isTouched && warning) {
            return warning;
          }
        }
        return (
          <NumberFormat
            type="text"
            customInput={StyledInput}
            InputProps={{
              readOnly: readOnly,
              endAdornment: endAdornment,
              startAdornment: startAdornment,
              ...InputProps,
            }}
            decimalScale={decimalScale}
            fixedDecimalScale={true}
            thousandSeparator={thousandSeparator}
            format={format}
            mask="_"
            disabled={isDisabled}
            error={Boolean(touched && error)}
            helperText={showError && _renderHelperText()}
            id={id}
            isNumericString
            value={field.value === null ? '' : field.value}
            onValueChange={(values) => {
              let value: string | null = values.value;

              if (max && Number(value) > max) {
                value = max.toString();
              } else if (min && Number(value) < min) {
                value = min.toString();
              }

              // we do this so that Yup validation doesn't get confused by an empty string
              value = value === '' ? null : value;

              onValueChange ? onValueChange(value) : field.onChange(value);
            }}
            warningText={_renderWarningText()}
            {...rest}
            onFocus={(e) => {
              rest.onFocus?.(e); // Preserve additional onFocus from `...rest`
              (field as any).onFocus?.(e); // Ensure RHF validation runs (Need to declare it as unknown to avoid type error. Typescript doesn't know that field is a Controller field which in fact has onFocus)
            }}
            onBlur={(e) => {
              rest.onBlur?.(e); // Preserve additional onBlur from `...rest`
              (field as any).onBlur?.(e); // Ensure RHF validation runs (Need to declare it as unknown to avoid type error. Typescript doesn't know that field is a Controller field which in fact has onBlur)
            }}
          />
        );
      }}
    />
  );
};

export default memo(NumberField);

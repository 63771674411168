import loanFormModel from '../../components/loan_application/form_models/loanFormModel';

import { ValidationSchema } from '../interfaces';
import { Validation } from '../Validation';

const {
  formField: { industryBorrowerReported },
} = loanFormModel;

export class IndustryBorrowerReportedValidation implements ValidationSchema {
  public name: string = industryBorrowerReported.name;
  public label = '';
  public dependsOn: string[] = [];
  public type = 'string';
  public required = {
    message: 'This field is required',
    value: true,
  };
  public nullable = false;
}
export default new Validation(new IndustryBorrowerReportedValidation());

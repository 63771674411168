import loanFormModel from '../../components/loan_application/form_models/loanFormModel';
import { ValidationSchema } from '../interfaces';
import { Validation } from '../Validation';
import * as Yup from 'yup';

const {
  formField: { nonProfitOrganizationType, businessTypeForProfitOrNonProfit },
} = loanFormModel;

export class NonProfitOrganizationTypeValidation implements ValidationSchema {
  public name: string = nonProfitOrganizationType.name;
  public label = '';
  public dependsOn: string[] = [businessTypeForProfitOrNonProfit.name];
  public type = 'string';
  public required = {
    message: '',
    value: false,
  };
  public nullable = true;

  public when(
    businessTypeForProfitOrNonProfitValue: string,
    schema: Yup.StringSchema
  ) {
    if (
      nonProfitOrganizationType.getRequired(
        businessTypeForProfitOrNonProfitValue
      )
    ) {
      return schema.required(nonProfitOrganizationType.requiredErrorMsg);
    }
    return schema;
  }
}
export default new Validation(new NonProfitOrganizationTypeValidation());
